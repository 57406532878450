<template>
	<div>
		<van-nav-bar title="个人中心" placeholder fixed />
		<!-- 头像 -->
		<div class="head_wrap">
			<van-image round width="100" height="100" src="https://img01.yzcdn.cn/vant/cat.jpeg" />
			<div class="user_name fs_28 fs_b">{{ userName }}</div>
			<div class="bind fs_20">登录绑定</div>
		</div>
		<!-- 金刚区 -->
		<van-grid :column-num="3" class="mt_8">
			<van-grid-item icon="https://img01.yzcdn.cn/vant/cat.jpeg" text="我的报修" @click="onMyRepairs" />
			<van-grid-item icon="service-o" text="投诉建议" @click="onMyComplaint" />
			<van-grid-item icon="service-o" text="修改信息" @click="onModify" />
		</van-grid>

		<van-cell title="我的订单" class="mt_8" :border="false" is-link />
		<div class="split_8 mt_8"></div>
		<van-cell title="账号安全中心" class="mt_8" is-link />
		<van-cell title="帮助与反馈" class="mt_8" is-link />
		<van-cell title="给我们好评" class="mt_8" :border="false" is-link />
		<div class="logout"><van-button type="primary" round block @click="logout">退出登录</van-button></div>
	</div>
</template>
<script>
import { Dialog } from 'vant';
export default {
	props: {},
	components: {},
	data() {
		return {
			msg: '个人中心',
			userName: '李建刚',
			userInfo:{},
		};
	},
	created() {
		var user = localStorage.getItem('userInfo');
		if (user) {
			user = JSON.parse(user);
			this.userInfo = user			
		}
		console.log(this.userInfo);
	},
	mounted() {},
	methods: {
		onMyRepairs() {
			console.log('我的报修');
		},
		onMyComplaint() {
			console.log('我的投诉建议');
		},
		onModify() {
			console.log('修改信息');
		},
		// 退出登录
		logout() {
			Dialog.confirm({
				title: '系统提示',
				message: '是否退出登录？'
			})
				.then(() => {
					// on confirm
					localStorage.removeItem('userInfo');
					this.$router.replace({
						name: 'Login'
					});
				})
				.catch(() => {
					// on cancel
				});
		}
	}
};
</script>
<style lang="scss" scoped>
.head_wrap {
	height: 100px;
	margin: 24px;
	display: flex;
	justify-content: center;
	.user_name {
		flex: 1;
		margin-left: 24px;
		color: #333333;
		display: flex;
		align-items: center;
	}
	.bind {
		display: flex;
		align-items: center;
		color: #666666;
	}
}
.logout {
	margin: 24px;
}
</style>
